body {
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	background-color: #fff;
}

h1 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 2rem;
	line-height: 2.75rem;
	font-weight: 600;
}

h2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.75rem;
	line-height: 2.5rem;
	font-weight: 600;
}

h3 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 600;
}

h4 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 600;
}

h5 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h6 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

p {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	line-height: 1.5rem;
	font-weight: 400;
}

a {
	border-bottom: 0px solid transparent;
	text-decoration: none;
	background-color: #fff;
	color: #002147;
}

ul {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-left: 40px;
}

ol {
	margin-top: 0px;
	margin-bottom: 20px;
	padding-left: 40px;
}

strong {
	font-weight: bold;
}

blockquote {
	margin-top: 2.5rem;
	margin-bottom: 1.5rem;
	padding: 0px 1rem;
	border-left: 5px solid #e2e2e2;
	font-size: 1rem;
	line-height: 1.5rem;
}

figure {
	margin-bottom: 20px;
}

figcaption {
	margin-top: 7px;
	color: rgba(51, 51, 51, 0.56);
	font-size: 0.875rem;
	line-height: 20px;
	text-align: center;
}

.button {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	padding: 0.75rem 1.25rem;
	border: 2px none transparent;
	background-color: #9b0c23;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	background-color: #7c0f29;
}

.button.w--current {
	background-color: #2e80b6;
	cursor: pointer;
}

.button.secondary {
	background-color: #002147;
	color: #fff;
	cursor: pointer;
}

.button.secondary:hover {
	background-color: rgba(0, 33, 71, 0.8);
}

.navigation-link {
	margin-right: 1px;
	padding: 1rem 1.4rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #002147;
	line-height: 1rem;
	font-weight: 500;
}

.navigation-link:hover {
	box-shadow: inset 0 3px 0 0 #9b0c23;
	color: #9b0c23;
}

.navigation-link.w--current {
	box-shadow: inset 0 3px 0 0 #9b0c23;
	color: #9b0c23;
}

.navigation-menu {
	position: static;
	display: inline-block;
}

.events-and-links-section {
	position: relative;
	padding-top: 3.5rem;
	padding-bottom: 3.5rem;
}

.hero-slider {
	position: relative;
	width: 100%;
	height: 75vh;
	background-color: transparent;
}

.slider-navigation {
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.slider-arrow-link.right {
	position: absolute;
}

.slider-arrow-link.left {
	position: absolute;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.slider-icon-circle {
	width: 50px;
	height: 50px;
	padding: 6px;
	font-size: 2.06rem;
}

.slider-icon-circle.left {
	background-image: url('/20181016082423/assets/images/left-arrow-gray.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: transparent;
}

.slider-icon-circle.left:hover {
	background-image: url('/20181016082423/assets/images/left-arrow-color-04.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.slider-icon-circle.left.small {
	width: 35px;
	height: 35px;
	margin-left: 10px;
}

.slider-icon-circle.right {
	position: absolute;
	background-image: url('/20181016082423/assets/images/right-arrow-gray.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: transparent;
}

.slider-icon-circle.right:hover {
	background-image: url('/20181016082423/assets/images/right-arrow-color-05.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.slider-icon-circle.right.small {
	width: 35px;
	height: 35px;
	margin-right: 10px;
}

.social-icon-link {
	padding: 3px 3px;
	opacity: 1;
	-webkit-transition: opacity 150ms ease;
	transition: opacity 150ms ease;
	background-color: transparent;
}

.social-icon-link:hover {
	opacity: 0.5;
}

.footer-div {
	padding-top: 2.25rem;
	padding-bottom: 1.5rem;
	border-top: 3px solid #9b0c23;
	background-color: #002147;
}

.footer-link {
	display: inline;
	margin-bottom: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #c9c9c9;
	font-size: 0.875rem;
	text-align: right;
	text-decoration: none;
	background-color: transparent;
}

.footer-link:hover {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.8);
	color: #fff;
}

.footer-link.w--current {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.8);
	color: #fff;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.container {
	position: relative;
	display: block;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
}

.container.flex-horizontal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.container.flex-display {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.container.extra-padding {
	padding: 1rem;
}

.container.header-flex {
	height: 100%;
	padding-left: 1rem;
	display: flex;
	justify-content: space-between;
}

.container.inside-page-container {
	padding-bottom: 2rem;
	background-color: #fff;
	min-height: 500px;
}

.container.extra-right-padding {
	padding-right: 4rem;
	padding-left: 1rem;
}

.navbar {
	position: relative;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	float: right;
	background-color: transparent;
}

.signoff {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #181818;
}

.grip-subtext {
	display: block;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.5rem;
	line-height: 8px;
	text-align: right;
}

.location-footer-text {
	display: inline-block;
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #c9c9c9;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-align: left;
}

.location-footer-text.no-margin {
	margin-bottom: 0rem;
}

.slide-mask {
	background-image: -webkit-linear-gradient(270deg, #000, #fff);
	background-image: linear-gradient(180deg, #000, #fff);
}

.number-heading {
	margin-bottom: 0.5rem;
	font-family: Merriweather, serif;
	color: #9b0c23;
	font-size: 2.25rem;
	line-height: 2.5rem;
	font-weight: 700;
	text-align: center;
}

.footer-heading {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 500;
	text-align: left;
	text-transform: uppercase;
}

.footer-heading.right-align {
	text-align: right;
}

.list-item {
	margin-bottom: 0.35rem;
	font-family: Montserrat, sans-serif;
}

.header-section {
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 2;
	box-shadow: 0 1px 6px 0 #333;
	background-color: #fff;
}

.header-inner-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1 1 70%;
	justify-content: space-between;
}

.header-logo {
	flex: 1 1 30%;
}

.header-inner-filler {
	height: 36px;
}

.logo {
	width: 100%;
}

.logo.w--current {
	display: inline-block;
	width: 5rem;
	height: 5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.logo-home {
	width: 210px;
	margin-top: 1.9rem;
	margin-bottom: 1rem;
	margin-left: 0.5rem;
	flex: 1 1 30%;
}

.logo-home.w--current {
	width: 210px;
	margin-top: 1.9rem;
}

.slide-overlay {
	position: absolute;
	right: 0px;
	bottom: 5rem;
	max-width: 800px;
	min-width: 650px;
	padding: 2rem 6vw 2rem 1.5rem;
	border-top: 2px solid #9b0c23;
	background-color: rgba(0, 33, 71, 0.92);
	background-image: url('/20181016082423/assets/images/white-zig-zag.svg');
	background-position: 0px 0px;
	background-size: 12px 12px;
}

.slider-header {
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #fff;
	font-size: 2.125rem;
	line-height: 2.75rem;
	font-weight: 600;
}

.search-button {
	width: 35px;
	height: 2rem;
	float: right;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	background-color: #002147;
	background-image: url('/20181016082423/assets/images/mag-glass.svg');
	background-position: 40% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-button.desktop {
	text-indent: 9999px;
}

.search-button:hover {
	background-color: rgba(0, 33, 71, 0.85);
}

.search-input {
	width: 170px;
	height: 2rem;
	margin-bottom: 0px;
	float: left;
	border-style: none;
	border-color: transparent;
	border-top-width: 1px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	background-color: #e0e4e9;
	color: #333;
}

.feature-div {
	display: inline-block;
	width: 18%;
	margin-top: 1.5rem;
	text-align: center;
}

.half-div {
	display: inline-block;
	width: 50%;
	padding: 1rem;
	background-color: #fff;
}

.footer-column {
	display: inline-block;
	width: 31%;
	height: auto;
	padding-right: 1rem;
	padding-left: 1rem;
}

.footer-social-links {
	text-align: center;
}

.phone-link {
	display: inline-block;
	margin-left: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #c9c9c9;
	font-size: 0.875rem;
	text-align: justify;
}

.email-link {
	display: inline;
	border-bottom: 1px solid hsla(0, 0%, 95%, 0.8);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #c9c9c9;
	font-size: 0.875rem;
	text-align: left;
	text-decoration: none;
	background-color: transparent;
}

.email-link:hover {
	border-bottom-color: transparent;
	color: #fff;
}

.grip-footer-logo {
	width: 130px;
	height: 28px;
	float: right;
}

.copyright-text {
	display: inline-block;
	margin-right: 5px;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.75rem;
}

.inside-body-section {
	position: relative;
	display: block;
	padding-top: 5rem;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	background-image: url('/20181016082423/assets/images/gray-zig-zag.svg'), -webkit-linear-gradient(270deg, #ebebeb, #ebebeb);
	background-image: url('/20181016082423/assets/images/gray-zig-zag.svg'), linear-gradient(180deg, #ebebeb, #ebebeb);
	background-position: 0px 0px, 0px 0px;
	background-size: 12px 12px, auto;
}

.nav-drop-wrap {
	position: relative;
	margin-right: 1px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.nav-drop-toggle {
	position: relative;
	width: 100%;
	padding: 0px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle:hover {
	color: #9b0c23;
}

.nav-drop-toggle.w--open {
	box-shadow: inset 0 3px 0 0 #9b0c23;
	color: #9b0c23;
}

.nav-drop-toggle-link {
	display: inline-block;
	width: 100%;
	padding: 1rem 1.4rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #002147;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
}

.nav-drop-toggle-link:hover {
	box-shadow: inset 0 3px 0 0 #9b0c23;
	color: #9b0c23;
}

.nav-drop-toggle-link.w--current {
	box-shadow: inset 0 3px 0 0 #9b0c23;
	color: #9b0c23;
}

.nav-drop-list {
	background: #a8a8a8;
}

.nav-drop-list.w--open {
	padding: 0.25rem 0.5rem 0.5rem;
	background-color: #002147;
}

.nav-drop-list.align-right.w--open {
	position: absolute;
	right: 0px;
}

.nav-drop-list-link {
	padding: 0.75rem 1rem 0.75rem 0.75rem;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.875rem;
}

.nav-drop-list-link:hover {
	background-color: #fff;
	color: #002147;
	cursor: pointer;
}

.nav-drop-list-link.w--current {
	background-color: #fff;
	color: #002147;
}

.signoff-link {
	display: inline-block;
	margin-right: 0.75rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #dbdbdb;
	font-size: 0.8rem;
	line-height: 1.25rem;
	border-bottom: 1px solid transparent;
	background-color: transparent;
}

.signoff-link:hover {
	border-bottom: 1px solid #797979;
	color: #a8a8a8;
}

.main-content-wrap.with-left-sidebar {
	width: 73%;
	padding-top: 2rem;
	padding-right: 1.5rem;
	float: right;
}

.main-content-wrap.with-right-sidebar {
	width: 75%;
	padding-top: 2rem;
	padding-left: 2rem;
	float: left;
}

.main-content-wrap.full-width-content {
	display: block;
	width: 80%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-right: 2rem;
	padding-left: 2rem;
}

.main-content-wrap.both-sidebars {
	display: block;
	width: 56%;
	padding-top: 2rem;
	padding-right: 2rem;
	padding-left: 2rem;
	float: left;
}

.section-div {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.styles {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.h1-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 2rem;
	line-height: 2.75rem;
	font-weight: 600;
}

.h1-link:hover {
	border-bottom-color: transparent;
	color: #777;
}

.h2-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.75rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.h2-link:hover {
	border-bottom-color: transparent;
	color: #777;
}

.h3-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.h3-link:hover {
	border-bottom-color: transparent;
	color: #777;
}

.h4-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 600;
}

.h4-link:hover {
	border-bottom-color: transparent;
	color: #777;
}

.h5-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
}

.h5-link:hover {
	border-bottom-color: transparent;
	color: #777;
}

.h6-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	line-height: 1.5rem;
	font-weight: 600;
}

.h6-link:hover {
	border-bottom-color: transparent;
	color: #777;
}

.navigation-wrap {
	position: relative;
}

.top-navigation {
	padding-bottom: 0.25rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.top-nav-link {
	display: inline-block;
	padding: 1.125rem 0.75rem 0.75rem;
	float: left;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #5d5d5d;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 400;
}

.top-nav-link:hover,
.top-nav-link.active {
	color: #9b0c23;
}

.top-nav-search-wrap {
	display: inline-block;
	margin-bottom: 0px;
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	float: right;
}

.slider-caption {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #fff;
	line-height: 1.5rem;
	font-weight: 400;
}

.slider-caption a {
	background-color: transparent;
	cursor: pointer;
	color: #fff !important;
	border: 0px !important;
	text-decoration: underline;
}

.at-a-glance-section {
	padding: 2.5rem 1rem;
	background-color: #f1f1f1;
}

.cb-heading {
	font-family: Merriweather, serif;
	color: #e8bb4e;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 600;
	text-align: left;
	text-transform: uppercase;
}

.cb-heading.centered {
	color: #002147;
	text-align: center;
}

.stat-description {
	font-family: Montserrat, sans-serif;
	color: #002147;
	font-size: 1rem;
	line-height: 1.25rem;
}

.event-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 95%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.event-wrap.extra-bottom-margin {
	margin-bottom: 1rem;
}

.event-date {
	display: inline-block;
	margin-right: 1rem;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
}

.event-name {
	display: inline;
	border-bottom: 1px dotted #767676;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #002147;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.event-name:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.quick-link {
	display: block;
	width: 90%;
	margin-bottom: 0.65rem;
	padding: 1rem 1rem 1rem 1.5rem;
	border-left: 6px solid #002147;
	background-color: #9b0c23;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 500;
}

.quick-link:hover {
	background-color: #7c0f29;
}

.event-details-wrap {
	max-width: 83%;
}

.news-section {
	padding-top: 2.5rem;
	padding-bottom: 2rem;
	background-color: #002147;
	background-image: url('/20181016082423/assets/images/white-zig-zag.svg');
	background-position: 0px 0px;
	background-size: 12px 12px;
	background-attachment: fixed;
}

.centered {
	text-align: center;
}

.centered.white-h3 {
	color: #fff;
	font-family: Merriweather, serif;
}

.centered.white-h3 a {
	background-color: transparent;
}

.news-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.news-item {
	width: 31%;
	padding: 1.25rem 1.25rem 1.5rem;
	background-color: #fff;
	box-shadow: none;
}

.news-heading {
	display: inline;
	border-bottom: 1px dotted #767676;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #9b0c23;
	font-size: 1.35rem;
	line-height: 1.65rem;
	font-weight: 600;
}

.news-heading:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.news-description {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	line-height: 1.35rem;
	font-weight: 400;
}

.news-heading-wrap {
	margin-bottom: 1rem;
}

.news-item-date {
	margin-bottom: 0.75rem;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
}

.centered-link-wrap {
	margin-top: 1.5rem;
	text-align: center;
}

.schools-section {
	padding-top: 3.5rem;
	padding-bottom: 3.5rem;
}

.schools-slider {
	margin-top: 1.5rem;
	background-color: transparent;
}

.small-slider-link {
	width: 55px;
	height: 55px;
}

.slide-nav {
	display: none;
}

.school-slide {
	width: 25%;
	padding: 1rem;
}

.school-slider-mask {
	display: block;
	width: 90%;
	margin-right: auto;
	margin-left: auto;
}

.school-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 250px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	border-bottom: 0px !important;
}

.school-link-block:hover {
	padding-bottom: 0.5rem;
	background-position: 0px 0px, 50% 50%;
}

.school-name {
	width: 100%;
	padding: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 500;
	text-align: center;
}

.link-wrap {
	display: block;
	margin-bottom: 1rem;
}

.link-wrap.right-align {
	margin-bottom: 0.5rem;
	text-align: right;
}

.signoff-links-wrap {
	display: inline-block;
}

.top-nav-links-wrap {
	display: inline-block;
	float: right;
}

.features-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.alert-bar {
	display: none;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	background-color: #9b0c23;
}

.alert-bar a {
	background-color: transparent;
}

.alert-text {
	display: inline;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.alert-text a {
	margin-left: 5px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	text-decoration: underline;
}

.slide-1-image {
	width: 100%;
	height: 100%;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-2-image {
	width: 100%;
	height: 100%;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-3-image {
	width: 100%;
	height: 100%;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.intro-paragraph {
	font-family: Montserrat, sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.text-link {
	border-bottom: 1px dotted rgba(27, 75, 34, 0.8);
	font-family: Montserrat, sans-serif;
	color: #002147;
	line-height: 1.5rem;
	font-weight: 400;
}

.text-link:hover {
	color: #767676;
}

.block-quote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #9b0c23;
	font-family: Montserrat, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.form-field-label {
	margin-bottom: 0.35rem;
	font-family: Montserrat, sans-serif;
	line-height: 1.5rem;
	font-weight: 400;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	margin-top: 1rem;
	background-color: #98e4a3;
}

.screen-message.error {
	margin-top: 1rem;
	background-color: #f3baba;
}

.left-navigation {
	position: relative;
	top: -2rem;
	width: 23%;
	float: left;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
	margin-top: 1px;
}

.breadcrumbs-list-item {
	display: inline-block;
}

.inside-page-container a.breadcrumb-link {
	display: inline-block;
	margin-right: 0.45rem;
	border-bottom: 1px dotted #7a7a7a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #747474;
	font-size: 0.75rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.inside-page-container a.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #333;
}

.breadcrumb-link.w--current {
	border-bottom-color: transparent;
	color: #333;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.45rem;
	color: #747474;
	font-size: 0.75rem;
}

.inside-page-container .left-nav-section-link {
	display: block;
	margin-bottom: 1rem;
	padding: 1.125rem 1.125rem 1.125rem 1.25rem;
	background-color: #9b0c23;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 500;
}

.nested-unordered-list {
	margin-bottom: 0px;
}

.left-nav-list-item-2 {
	margin-right: 1rem;
	margin-left: 1rem;
}

.left-nav-list-item-2.no-right-margin {
	margin-right: 0rem;
}

.inside-page-container a.left-nav-link-level-2 {
	display: block;
	margin-left: 1rem;
	padding: 0.65rem 0.75rem 0.65rem 0.5rem;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.8rem;
}

.inside-page-container a.left-nav-link-level-2:hover {
	padding-right: 0.5rem;
	padding-left: 0.75rem;
	background-color: rgba(0, 33, 71, 0.08);
}

.inside-page-container a.left-nav-link-level-2.w--current,
.inside-page-container a.left-nav-link-level-1.w--current {
	padding-left: 0.75rem;
	border-left: 2px solid rgba(0, 33, 71, 0.8);
	background-color: rgba(0, 33, 71, 0.08);
}

.inside-page-container a.left-nav-link-level-2.w--current:hover {
	padding-right: 0.75rem;
}

.inside-page-container a.left-nav-link-level-3 {
	display: block;
	margin-left: 1rem;
	padding: 0.65rem 0.75rem 0.65rem 0.5rem;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.75rem;
}

.inside-page-container a.left-nav-link-level-3:hover {
	padding-right: 0.5rem;
	padding-left: 0.75rem;
	background-color: rgba(0, 33, 71, 0.08);
}

.left-nav-link-level-3.w--current {
	background-color: rgba(20, 76, 141, 0.1);
}

.inside-page-container a.left-nav-link-level-1 {
	display: block;
	margin-left: 1rem;
	padding: 0.65rem 0.75rem 0.65rem 0.5rem;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.inside-page-container a.left-nav-link-level-1:hover {
	padding-right: 0.5rem;
	padding-left: 0.75rem;
	background-color: rgba(0, 33, 71, 0.08);
}

.ul-text-block {
	margin-bottom: 0.35rem;
	font-family: Montserrat, sans-serif;
}

.unordered-list {
	margin-bottom: 20px;
}

.right-sidebar {
	width: 18%;
	margin-right: 1.5rem;
	padding-top: 3rem;
	float: right;
}

.right-sidebar h2 {
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.right-sidebar h4 {
	line-height: 1.75rem;
}

.right-sidebar p {
	font-size: 0.85rem;
	line-height: 1.25rem;
}

.alert-link {
	margin-left: 5px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	text-decoration: underline;
}

.body {
	font-family: Montserrat, sans-serif;
}

.alert-information {
	display: block;
	min-height: 30px;
	padding-top: 5px;
	padding-left: 2.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20181016082423/assets/images/alert.svg');
	background-position: 0px 0px;
	background-size: 30px 30px;
	background-repeat: no-repeat;
}

.copyright-text-wrap {
	display: inline-block;
	margin-right: 1rem;
}

.close-alert-link {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 16px;
	height: 18px;
	margin-right: 1rem;
	background-color: transparent;
	padding: 0px;
}

html.w-mod-js *[data-ix="fade-in-bottom-page-loads"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="fade-in-left-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(-50px, 0px);
	-ms-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-right-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(50px, 0px);
	-ms-transform: translate(50px, 0px);
	transform: translate(50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-top-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, -50px);
	-ms-transform: translate(0px, -50px);
	transform: translate(0px, -50px);
}

html.w-mod-js *[data-ix="fade-in-bottom-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="bounce-in-scroll-in"] {
	opacity: 0;
	-webkit-transform: scale(0.6000000000000006, 0.6000000000000006);
	-ms-transform: scale(0.6000000000000006, 0.6000000000000006);
	transform: scale(0.6000000000000006, 0.6000000000000006);
}

html.w-mod-js *[data-ix="scale-on-scroll"] {
	opacity: 0;
	-webkit-transform: scale(0.01, 0.01);
	-ms-transform: scale(0.01, 0.01);
	transform: scale(0.01, 0.01);
}

html.w-mod-js *[data-ix="news-cb1"] {
	opacity: 0.7500000000000001;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="news-cb2"] {
	opacity: 0.7500000000000001;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="news-cb3"] {
	opacity: 0.7000000000000001;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="quick-link-appear"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="slide-overlay-appear"] {
	opacity: 0;
	-webkit-transform: scale(0.8, 0.8);
	-ms-transform: scale(0.8, 0.8);
	transform: scale(0.8, 0.8);
}

/* media queries */
@media (max-width: 991px) {
	.navigation-link {
		display: block;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid #dbdbdb;
		color: #fff;
	}

	.navigation-link:hover {
		background-color: #fff;
		box-shadow: none;
		color: #002147;
	}

	.navigation-link.w--current {
		background-color: #fff;
		box-shadow: inset 0 3px 0 0 #fff;
		color: #002147;
	}

	.navigation-link.top-border {
		border-top: 1px solid hsla(0, 0%, 100%, 0.5);
	}

	.navigation-menu {
		position: absolute;
		width: 70vw;
		margin-top: 56px;
		background-color: #002147;
	}

	.events-and-links-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.hero-slider {
		position: relative;
		z-index: 1;
		height: auto;
		min-height: 350px;
	}

	.slide-1 {
		width: 100%;
		margin-top: 0px;
	}

	.slider-arrow-link.right {
		width: 45px;
		height: 45px;
		margin-right: 0px;
		margin-bottom: 5.5rem;
	}

	.slider-arrow-link.left {
		width: 45px;
		height: 45px;
		margin-bottom: 5.5rem;
		margin-left: 0px;
	}

	.slider-icon-circle.left {
		width: 35px;
		height: 35px;
	}

	.slider-icon-circle.left:hover {
		background-image: url('/20181016082423/assets/images/left-arrow-color-04.svg');
		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.slider-icon-circle.right {
		width: 35px;
		height: 35px;
	}

	.slider-icon-circle.right:hover {
		background-image: url('/20181016082423/assets/images/right-arrow-color-05.svg');
		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.container {
		width: 100%;
	}

	.container.header-flex {
		padding-left: 0rem;
		flex-direction: column;
	}

	.navbar {
		bottom: 56px;
	}

	.slide-mask {
		background-color: #002147;
		background-image: url('/20181016082423/assets/images/white-zig-zag.svg');
		background-position: 0px 0px;
		background-size: 12px 12px;
	}

	.header-section {
		height: auto;
	}

	.logo-home {
		display: block;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.logo-home.w--current {
		display: block;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.slide-overlay {
		position: static;
		right: 0rem;
		bottom: 0rem;
		width: 100%;
		min-width: 100%;
		padding: 1.5rem 5rem 2.5rem;
		background-color: transparent;
		background-image: none;
	}

	.search-button {
		width: 25%;
		height: 2.25rem;
		padding-top: 8px;
		padding-bottom: 8px;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		font-size: 0.89rem;
		background-image: url('/20181016082423/assets/images/mag-glass.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		text-indent: -9999px;
	}

	.search-input {
		width: 75%;
		height: 2.25rem;
	}

	.half-div {
		padding-left: 1.5rem;
	}

	.grip-footer-logo {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}

	.copyright-text {
		margin-right: 5px;
	}

	.inside-body-section {
		padding-top: 1.75rem;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.nav-drop-wrap {
		border-bottom: 1px solid #dbdbdb;
	}

	.nav-drop-toggle-link {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		padding-left: 1.5rem;
		color: #fff;
		text-align: left;
	}

	.nav-drop-toggle-link:hover {
		background-color: #fff;
		box-shadow: none;
		color: #002147;
	}

	.nav-drop-toggle-link.w--current {
		background-color: #fff;
		box-shadow: inset 0 3px 0 0 #fff;
		color: #002147;
	}

	.main-content-wrap.with-left-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.main-content-wrap.with-right-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1.5rem;
		padding-left: 0rem;
		float: none;
	}

	.main-content-wrap.full-width-content {
		width: 90%;
		padding-right: 1.5rem;
		padding-left: 0rem;
	}

	.main-content-wrap.both-sidebars {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1.5rem;
		padding-left: 0rem;
		float: none;
	}

	.navigation-wrap {
		top: 0rem;
		display: block;
		width: 100%;
		height: 3.5rem;
		background-color: #002147;
	}

	.top-navigation {
		width: 85%;
	}

	.top-navigation.top-nav-flex {
		width: 60%;
		float: right;
		border-bottom-style: none;
	}

	.top-nav-search-wrap.mobile-search-wrap {
		display: block;
		padding: 0.75rem 1.5rem;
		float: none;
	}

	.stat-description {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.quick-link {
		width: 95%;
		padding-left: 1rem;
	}

	.news-item {
		width: 31%;
	}

	.school-slide {
		width: 50%;
	}

	.mobile-menu-button {
		display: inline-block;
		height: 3.5rem;
		padding: 12px 16px;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.mobile-menu-button:hover {
		background-color: hsla(0, 0%, 100%, 0.2);
	}

	.mobile-menu-button.w--open {
		background-color: hsla(0, 0%, 100%, 0.2);
	}

	.hamburger-icon {
		color: #fff;
		font-size: 2rem;
		line-height: 2rem;
	}

	.mobile-search {
		display: block;
		width: 50%;
		background-color: #fff;
		box-shadow: 1px 1px 4px 0 #5d5d5d;
	}

	.features-wrap {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.alert-text {
		display: inline;
	}

	.slide-1-image {
		height: 350px;
	}

	.slide-2-image {
		height: 350px;
	}

	.slide-3-image {
		height: 350px;
	}

	.right-sidebar {
		display: block;
		width: 90%;
		margin-top: 2rem;
		margin-right: auto;
		margin-left: auto;
		padding-top: 1rem;
		padding-right: 1.5rem;
		float: none;
		border-top: 1px solid #dbdbdb;
	}

	.mobile-search-dd-button {
		width: 64px;
		height: 3.5rem;
		background-image: url('/20181016082423/assets/images/mag-glass.svg');
		background-position: 50% 50%;
		background-size: 25px 25px;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.mobile-search-dd-button:hover {
		background-color: hsla(0, 0%, 100%, 0.2);
	}

	.header-search-container {
		position: absolute;
		display: none;
		overflow: visible;
		width: 100%;
		height: 0px;
	}

	.header-inner-filler {
		display: none;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.85rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 2.35rem;
	}

	h3 {
		font-size: 1.45rem;
		line-height: 2.125rem;
	}

	.button {
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.events-and-links-section {
		padding-right: 1rem;
		padding-left: 1rem;
		overflow: hidden;
	}

	.slide-1 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.slider-navigation {
		font-size: 0.75rem;
	}

	.slider-arrow-link.right {
		margin-bottom: 4.5rem;
	}

	.slider-arrow-link.left {
		margin-bottom: 4.5rem;
	}

	.slider-icon-circle.left.small {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.slider-icon-circle.right.small {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.footer-div {
		padding-bottom: 1rem;
	}

	.footer-link {
		line-height: 1.45rem;
	}

	.container.flex-horizontal {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.container.flex-display {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.extra-padding.mobile-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.location-footer-text {
		line-height: 1.45rem;
	}

	.number-heading {
		font-size: 1.85rem;
		line-height: 2.25rem;
	}

	.footer-heading {
		text-align: center;
	}

	.footer-heading.centered {
		margin-top: 0.5rem;
	}

	.footer-heading.right-align {
		margin-top: 0.5rem;
		text-align: center;
	}

	.logo-home {
		width: 200px;
	}

	.logo-home.w--current {
		width: 200px;
	}

	.slide-overlay {
		width: 100%;
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.slider-header {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	.feature-div {
		display: inline-block;
		width: 33%;
	}

	.half-div {
		width: 100%;
		margin-bottom: 0.5rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-column {
		width: 80%;
		margin-bottom: 1rem;
		text-align: center;
	}

	.grip-footer-logo {
		position: static;
	}

	.copyright-text {
		text-align: center;
	}

	.inside-body-section {
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.signoff-link {
		margin-right: 0rem;
	}

	.main-content-wrap.with-left-sidebar {
		width: 95%;
		padding-top: 1rem;
		padding-right: 1rem;
	}

	.main-content-wrap.with-right-sidebar {
		width: 95%;
		padding-top: 1rem;
		padding-right: 1rem;
	}

	.main-content-wrap.full-width-content {
		width: 95%;
		padding-top: 1rem;
		padding-right: 1rem;
	}

	.main-content-wrap.both-sidebars {
		width: 95%;
		padding-top: 1rem;
		padding-right: 1rem;
	}

	.h1-link {
		font-size: 1.85rem;
		line-height: 2.5rem;
	}

	.h2-link {
		font-size: 1.6rem;
		line-height: 2.35rem;
	}

	.h3-link {
		font-size: 1.45rem;
		line-height: 2.125rem;
	}

	.top-navigation {
		width: 100%;
		padding-bottom: 0rem;
	}

	.top-navigation.top-nav-flex {
		width: 68%;
	}

	.top-nav-link {
		display: inline-block;
		padding-top: 0.75rem;
		padding-bottom: 0.5rem;
	}

	.top-nav-search-wrap {
		padding-top: 0.4rem;
		padding-bottom: 0.25rem;
	}

	.at-a-glance-section {
		padding-bottom: 1.5rem;
	}

	.event-wrap {
		width: 100%;
	}

	.quick-link {
		width: 100%;
	}

	.news-section {
		padding: 1.5rem 1rem;
	}

	.news-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.news-item {
		width: 100%;
		margin-bottom: 1rem;
	}

	.schools-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.small-slider-link {
		width: 45px;
		height: 45px;
	}

	.school-slide {
		width: 50%;
	}

	.school-slider-mask {
		width: 89%;
	}

	.link-wrap.right-align {
		text-align: center;
	}

	.signoff-links-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-right: 0px;
		padding-top: 0.25rem;
		padding-bottom: 0.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.mobile-search {
		width: 75%;
	}

	.features-wrap {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	.alert-text {
		line-height: 1.25rem;
	}

	.slide-1-image {
		background-position: 0px 0px, 50% 50%;
	}

	.intro-paragraph {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.block-quote {
		font-size: 1rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.right-sidebar {
		width: 95%;
		padding-right: 1rem;
	}

	.ordered-list {
		padding-left: 20px;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 2.25rem;
	}

	.navigation-menu {
		width: 80vw;
	}

	.slider-arrow-link.right {
		display: block;
		margin-bottom: 5.5rem;
	}

	.slider-arrow-link.left {
		margin-bottom: 5.5rem;
	}

	.slider-icon-circle {
		margin-right: 0px;
		margin-left: 0px;
		float: none;
		text-align: left;
	}

	.slider-icon-circle.left {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.slider-icon-circle.right {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.container {
		padding-right: 15px;
		padding-left: 15px;
	}

	.container.flex-display {
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.extra-padding.mobile-flex {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.container.header-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.container.inside-page-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.logo-home {
		display: block;
		width: 180px;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.logo-home.w--current {
		width: 180px;
	}

	.slide-overlay {
		padding-right: 1.5rem;
		padding-bottom: 3.5rem;
		padding-left: 1.5rem;
	}

	.slider-header {
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.search-button {
		width: 30%;
		padding-right: 8px;
		padding-left: 8px;
		font-size: 0.75rem;
	}

	.search-input {
		width: 70%;
	}

	.feature-div {
		width: 100%;
		margin-top: 0.5rem;
	}

	.half-div {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.copyright-text {
		display: block;
		margin-right: auto;
		margin-left: auto;
		line-height: 1.5rem;
	}

	.h1-link {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.h2-link {
		line-height: 2.25rem;
	}

	.navigation-wrap {
		float: none;
	}

	.top-navigation {
		border-bottom-color: transparent;
	}

	.top-navigation.top-nav-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.top-nav-link {
		padding-right: 0.65rem;
		padding-left: 0.65rem;
		float: none;
	}

	.top-nav-search-wrap {
		position: relative;
		display: block;
		width: 205px;
		margin-right: auto;
		margin-left: auto;
		padding: 0rem;
		float: none;
		text-align: center;
	}

	.top-nav-search-wrap.mobile-search-wrap {
		width: 100%;
		min-width: 130px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.slider-caption {
		font-size: 0.9rem;
		line-height: 1.25rem;
	}

	.stat-description {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.school-slide {
		width: 100%;
	}

	.school-slider-mask {
		width: 80%;
	}

	.signoff-links-wrap {
		padding-top: 0.5rem;
	}

	.top-nav-links-wrap {
		width: 100%;
		float: left;
		clear: left;
		text-align: center;
	}

	.mobile-search {
		display: block;
		width: 100%;
	}

	.features-wrap {
		margin-top: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.slide-1-image {
		height: 180px;
		background-position: 0px 0px, 50% 50%;
	}

	.slide-2-image {
		height: 325px;
	}

	.slide-3-image {
		height: 325px;
	}

	.block-quote {
		padding-right: 0.5rem;
	}

	.copyright-text-wrap {
		display: block;
		width: 100%;
		margin-right: 0rem;
	}
}



/****************** Webflow Styles ***********************/
/****************************************************/
html {
	font-size: 16px;
}
/* FONT SMOOTHING */
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
/* ADJUST MAIN NAV TO ACCOMODATE NARROWER DESKTOPS */
@media screen and (min-width: 992px) and (max-width: 1470px) {
	.navbar {
		right: 1rem;
	}
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
	.nav-drop-toggle-link,
	.navigation-link {
		padding: 1rem 0.75rem;
	}
}
/* ADJUST SLIDESHOW HEIGHT ON LAPTOPS */
@media screen and (min-width: 992px) and (max-height: 828px) {
	.hero-slider {
		height: 525px !important;
	}
}
/* CONTROL PHONE NUMBER COLORS */
.phone-link a[href^=tel] {
	/* footer phone number */
	-webkit-transition: all 200ms ease;
	color: #f1f1f1 !important;
	text-decoration: none;
	box-shadow: none;
}
/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
::-moz-selection {
	background-color: #797676;
	color: #ffffff;
}
/* Safari browser */
::selection {
	background-color: #797676;
	color: #ffffff;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0px;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	padding: 0.5rem 1.25rem;
}

.alert-text .cms-website-content-region p:last-child {
	margin-bottom: 0px;
}


@media (max-width: 767px) {
	.alert-text .cms-website-content-region p {
		line-height: 1.25rem;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul.navigation-menu {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.accesible-navigation-menu li.nav-drop-wrap {
	display: inline-block;
}

.accesible-navigation-menu li.nav-drop-wrap:last-child .container-wrapper {
	right: 0;
	left: auto;
}

.accesible-navigation-menu li.nav-drop-wrap .nav-drop-toggle-link.active {
	box-shadow: inset 0 3px 0 0 #9b0c23 !important;
	color: #9b0c23 !important;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul {
	display: none;
	padding: 0.25rem 0.5rem 0.5rem;
	background-color: #002147;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul a {
	padding: 0.75rem 1rem 0.75rem 0.75rem;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.875rem;
	display: inline-block;
	width: 100%;
	background-color: transparent;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul a.w--current {
	background-color: #fff;
	color: #002147;
	display: inline-block;
}

.accesible-navigation-menu li.nav-drop-wrap.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-drop-wrap.open .container-wrapper ul {
	display: inline-block;
}

.accesible-navigation-menu li.nav-drop-wrap .nav-drop-toggle-link.hover-dropdown {
	box-shadow: inset 0 3px 0 0 #9b0c23;
	color: #002147;
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}


.mobile-navigation-menu {
	background: #002147;
}

.mobile-navigation-menu li a.nav-a {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	color: #fff;
	text-align: left;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 1rem;
	font-weight: 500;
	background-color: transparent;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li.active a.nav-a {
	background-color: #fff;
	box-shadow: none;
	color: #002147;
}

.mobile-navigation-menu.mm-menu .mm-navbar a,
.mobile-navigation-menu.mm-menu .mm-navbar > * {
	color: #fff;
	font-family: Montserrat, sans-serif;
	line-height: 1rem;
	font-weight: 500;
	text-align: left;
}

.mobile-navigation-menu.mm-menu .mm-navbar a {
	background-color: transparent;
}

.mobile-navigation-menu .mm-listitem,
.mobile-navigation-menu .mm-listitem .mm-btn_next,
.mobile-navigation-menu .mm-listitem .mm-btn_next:before,
.mobile-navigation-menu .mm-listitem:after,
.mobile-navigation-menu .mm-listview,
.mobile-navigation-menu.mm-menu .mm-listview .mm-btn_next:after,
.mobile-navigation-menu.mm-menu .mm-btn:after,
.mobile-navigation-menu.mm-menu .mm-btn:before,
.mobile-navigation-menu .mm-navbar {
	border-color: #dbdbdb;
}

.mobile-navigation-menu .mm-listitem:after {
	left: 0;
}

.mobile-navigation-menu.mm-menu .mm-navbar {
	height: 54px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -6px;
}

.mobile-navigation-menu .mm-navbar__title {
	padding-top: 20px;
}


.mobile-navigation-menu .mm-btn_prev:before {
	left: 17px;
	top: 13px;
}

/********************* Homepage *********************/
/*****************************************************/
.number-heading p {
	font-family: Merriweather, serif;
	color: #9b0c23;
	font-size: inherit;
	line-height: inherit;
	font-weight: 700;
}

.number-heading p:last-child {
	margin-bottom: 0px;
}

.homeboxes .stat-description a,
.homeboxes .stat-description a:hover {
	font-family: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	border-bottom: 0px;
	background-color: transparent;
}

.quick-links ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.quick-links li {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.quick-links li a {
	display: block;
	width: 90%;
	margin-bottom: 0.65rem;
	padding: 1rem 1rem 1rem 1.5rem;
	border-left: 6px solid #002147;
	background-color: #9b0c23;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.25rem;
	border-bottom: 0px;
}

.quick-links li a:hover {
	color: #fff;
	background-color: #7c0f29;
}

.events-and-links-section h3.cb-title {
	background-color: #fff;
	font-family: Merriweather, serif;
	color: #002147;
}

.events-and-links-section h3.cb-title a,
.events-and-links-section h3.cb-title a:hover {
	border-bottom: 0px;
	color: #002147;
}

.news-section *,
.inside-page-container .news-section a,
.news-section.homeboxes a,
.inside-page-container .news-section a:hover,
.news-section.homeboxes a:hover,
.inside-page-container .news-section a:visited,
.news-section.homeboxes a:visited,
.inside-page-container .news-section a:link,
.news-section.homeboxes a:link {
	color: #fff;
}

.news-section h3.white-h3 a,
.news-section h3.white-h3 a:hover {
	border-bottom: 0px;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
}

.schools-section .centered a,
.schools-section .centered a:hover {
	border-bottom: 0px;
	color: #002147;
}

.schools-slider {
	text-align: left;
}

.school-slider-mask {
	white-space: normal;
}

.school-slider-mask,
.schools-slider {
	height: auto;
}

.school-slide {
	float: left;
}

@media (max-width: 991px) {
	.quick-links li a {
		width: 95%;
		padding-left: 1rem;
	}
}

@media (max-width: 767px) {
	.quick-links li a {
		width: 100%;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-caption * {
	color: #fff;
}

.slider-caption h1 {
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #fff;
	font-size: 2.125rem;
	line-height: 2.75rem;
	font-weight: 600;
}

.slide-overlay {
	opacity: 0;
	-webkit-transform: scale(0.8, 0.8);
	-ms-transform: scale(0.8, 0.8);
	transform: scale(0.8, 0.8);
	transition: opacity 800ms ease 0s, transform 900ms ease 0s;
}

.cycle-slide-active .slide-overlay {
	opacity: 1;
	transform: scaleX(1) scaleY(1) scaleZ(1);
}

.slide-overlay .button,
.slide-overlay .button:hover {
	border-bottom: 0px;
	color: #fff;
	font-weight: 500;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
}

@media (max-width: 767px) {
	.slider-caption h1 {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	.w-slide {
		height: auto;
	}

	.slide-overlay .button {
		font-size: 0.85rem;
		line-height: 1rem;
	}
}

@media (max-width: 479px) {
	.slider-caption h1 {
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

/****************** Inside Page ******************/
/*************************************************/
.inside-page-container img {
	height: auto;
	max-width: 100%;
}

.inside-page-container td img {
	max-width: none;
}

@media (max-width: 767px) {
	.inside-page-container .mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.inside-page-container .left-nav-section-link {
	padding: 0px;
	margin: 0px 0px 1rem 0px;
}

.inside-page-container .left-nav-section-link a {
	background-color: transparent;
}

.inside-page-container .left-nav-section-link a,
.inside-page-container .left-nav-section-link a:hover {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	border-bottom: 0px;
	text-decoration: none;
	display: inline-block;
	width: 100%;
	font-size: inherit;
	margin-bottom: 0px;
}

.inside-page-container .left-nav-section-link a {
	padding: 1.125rem 1.125rem 1.125rem 1.25rem;
}

.breadcrumbs-list .breadcrumbs-list-item:last-child .breadcrumb-divider {
	display: none;
}

.left-nav-link-level-2.active,
.left-nav-link-level-1.active,
.left-nav-link-level-3.active {
	padding-left: 0.75rem;
	border-left: 2px solid rgba(0, 33, 71, 0.8);
	background-color: rgba(0, 33, 71, 0.08);
}

.left-column-margin {
	height: 50px;
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar .box {
	margin-bottom: 1rem;
	font-size: 0.85rem;
	line-height: 1.25rem;
}

.right-sidebar .box h4,
.right-sidebar .box h3 {
	font-size: 1.25rem;
	line-height: 1.75rem;
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-weight: 600;
}

.right-sidebar .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar .box.tools td:first-child img {
	margin-right: 5px;
}


/********************* Footer **********************/
/*****************************************************/
.phone-link.desktop {
	display: inline;
}

.phone-link.mobile {
	display: none;
	background-color: transparent;
}

#GRIPFooterLogo {
	float: right;
	padding-top: 0px !important;
	line-height: 12px;
}

#GRIPFooterLogo a {
	background-color: transparent;
	cursor: pointer;
	font-family: inherit;
}

.footer-link.active {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.8);
	color: #fff;
}

.footer-links-container {
	width: calc(100% - 150px);
	float: left;
}

@media (max-width: 991px) {
	.phone-link.mobile {
		display: inline;
	}

	.phone-link.desktop {
		display: none;
	}
}

@media (max-width: 767px) {
	.footer-links-container {
		width: 100%;
		float: none;
		text-align: center;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.inside-page-container a,
.homeboxes a {
	border-bottom: 1px dotted rgba(27, 75, 34, 0.8);
	font-family: Montserrat, sans-serif;
	color: #002147;
	line-height: 1.5rem;
	font-weight: 400;
}

.inside-page-container a:hover,
.homeboxes a:hover {
	color: #767676;
}

.inside-page-container h1 a,
.homeboxes h1 a {
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 2rem;
	line-height: 2.75rem;
	font-weight: 600;
}

.inside-page-container h2 a,
.homeboxes h2 a {
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.75rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.inside-page-container h3 a,
.homeboxes h3 a {
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.inside-page-container h4 a,
.homeboxes h4 a {
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 600;
}

.inside-page-container h5 a,
.homeboxes h5 a {
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
}


.inside-page-container h6 a,
.homeboxes h5 a {
	margin-bottom: 1rem;
	border-bottom: 1px dotted #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Merriweather, serif;
	color: #002147;
	line-height: 1.5rem;
	font-weight: 600;
}

.inside-page-container h1 a:hover,
.inside-page-container h2 a:hover,
.inside-page-container h3 a:hover,
.inside-page-container h4 a:hover,
.inside-page-container h5 a:hover,
.inside-page-container h6 a:hover,
.homeboxes h1 a:hover,
.homeboxes h2 a:hover,
.homeboxes h3 a:hover,
.homeboxes h4 a:hover,
.homeboxes h5 a:hover,
.homeboxes h6 a:hover {
	border-bottom-color: transparent;
	color: #777;
}


@media (max-width: 767px) {
	.inside-page-container h1 a,
	.homeboxes h1 a {
		font-size: 1.85rem;
		line-height: 2.5rem;
	}

	.inside-page-container h2 a,
	.homeboxes h2 a {
		font-size: 1.6rem;
		line-height: 2.35rem;
	}

	.inside-page-container h3 a,
	.homeboxes h3 a {
		font-size: 1.45rem;
		line-height: 2.125rem;
	}
}

@media (max-width: 479px) {
	.inside-page-container h1 a,
	.homeboxes h1 a {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.inside-page-container h2 a,
	.homeboxes h2 a {
		line-height: 2.25rem;
	}
}


/* buttons */
.inside-page-container .button,
.inside-page-container a.primary,
.homeboxes a.primary,
.primary,
.inside-page-container button[type="submit"],
.inside-page-container .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	padding: 0.75rem 1.25rem;
	border: 2px none transparent;
	background-color: #9b0c23;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.primary:hover,
.inside-page-container a.primary:hover .inside-page-container button[type="submit"]:hover,
.inside-page-container .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover {
	background-color: #7c0f29;
	color: #fff;
}

.secondary,
.inside-page-container a.secondary,
.homeboxes a.secondary,
.inside-page-container button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-container .cms_form_button.secondary,
.inside-page-container .button.secondary {
	padding: 0.75rem 1.25rem;
	border: 2px none transparent;
	background-color: #002147;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.secondary:hover,
.inside-page-container a.secondary:hover,
.inside-page-container button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-container .cms_form_button.secondary:hover,
.inside-page-container .button:hover,
.homeboxes a.secondary:hover {
	background-color: rgba(0, 33, 71, 0.8);
	color: #fff;
}

.tertiary,
.inside-page-container button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-container a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.75rem 1.25rem;
	border: 2px none transparent;
	background-color: #002147;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.tertiary:hover,
.inside-page-container a.tertiary:hover,
.inside-page-container button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	background-color: rgba(0, 33, 71, 0.8);
	color: #fff;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: #98e4a3;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error,
#polls .session_message.error {
	background-color: #f3baba;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #9b0c23;
	color: white;
	font-weight: normal;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #002147;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-container input[type="text"],
.inside-page-container input[type="tel"],
.inside-page-container input[type="password"],
.inside-page-container input[type="email"],
.inside-page-container select,
.inside-page-container textarea,
.inside-page-container input[type="search"] {
	max-width: 100%;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #878787;
}

.inside-page-container textarea {
	resize: vertical;
}

.inside-page-container label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-container input[type="text"],
	.inside-page-container input[type="tel"],
	.inside-page-container input[type="password"],
	.inside-page-container input[type="email"],
	.inside-page-container select,
	.inside-page-container textarea,
	.inside-page-container input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #9b0c23;
	font-family: Montserrat, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
}
/* Custom landing pages */
.grid-container .contentbox-container .contentbox-container-inner {
	width: 100%;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	grid-column: 1 / span 12;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-container .content-box.default {
	margin-bottom: 20px;
}

.grid-container .content-box.slideshow.default {
	margin-bottom: 0px;
}

.grid-container .contentbox-container .content-box.only-title h2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.45rem;
	line-height: 2.125rem;
	text-align: center;
	font-weight: 600;
}

.grid-container .contentbox-container .content-box .content-box-title h2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #002147;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.grid-container .contentbox-container.grey-background .content-box.only-title h2 {
	font-family: Merriweather, serif;
	color: #002147;
	text-align: center;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 600;
	text-transform: uppercase;
}

.grid-container .contentbox-container.grey-background {
	padding: 0rem 1rem;
	background-color: #f1f1f1;
}

.grid-container .contentbox-container.grey-background .content-box {
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;
}

.grid-container .contentbox-container.grey-background .content-box.default .content-box-read-more {
	display: none;
}

.grid-container .contentbox-container.grey-background .content-box.default .content-box-title {
	order: 2;
}

.grid-container .contentbox-container.grey-background .content-box.default .content-box-title h2 {
	font-family: Montserrat, sans-serif;
	color: #002147;
	font-size: 1rem;
	line-height: 1.25rem;
	margin: 0px;
	font-weight: normal;
	text-align: center;
}

.grid-container .contentbox-container.grey-background .content-box.default .content-box-content {
	margin-bottom: 0.5rem;
	font-family: Merriweather, serif;
	color: #9b0c23;
	font-size: 2.25rem;
	line-height: 2.5rem;
	font-weight: 700;
	text-align: center;
	order: 1;
}

.grid-container .contentbox-container.grey-background .content-box.default .content-box-content p {
	font-family: Merriweather, serif;
	color: #9b0c23;
	font-size: inherit;
	line-height: inherit;
	font-weight: 700;
	margin: 0;
}

.grid-container .contentbox-container.blue-background {
	padding-top: 2.5rem;
	padding-bottom: 2rem;
	background-color: #002147;
	background-image: url('/20181016082423/assets/images/white-zig-zag.svg');
	background-position: 0px 0px;
	background-size: 12px 12px;
	background-attachment: fixed;
}

.grid-container .contentbox-container.blue-background .content-box .content-box-title h2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 600;
	text-align: center;
}

.grid-container .contentbox-container .content-box.image-background .school-slide {
	float: none;
	width: 100%;
}

.grid-container .contentbox-container .content-box.default ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.grid-container .contentbox-container .content-box.default ul li {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.grid-container .contentbox-container .content-box.default ul li a {
	display: block;
	width: 90%;
	margin-bottom: 0.65rem;
	padding: 1rem 1rem 1rem 1.5rem;
	border-left: 6px solid #002147;
	background-color: #9b0c23;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.25rem;
	border-bottom: 0px;
}

.grid-container .contentbox-container .content-box.default ul li a:hover {
	color: #fff;
	background-color: #7c0f29;
}

@media (max-width: 991px) {
	.grid-container .contentbox-container .content-box.default ul li a {
		width: 95%;
		padding-left: 1rem;
	}

	.grid-container .contentbox-container.default {
		padding: 1rem 2rem;
	}

	.grid-container .contentbox-container.blue-background {
		padding: 1rem 1rem;
	}
}

@media (max-width: 767px) {
	.grid-container .contentbox-container.grey-background {
		padding-bottom: 1.5rem;
	}

	.grid-container .contentbox-container.grey-background .content-box.default .content-box-content {
		font-size: 1.85rem;
		line-height: 2.25rem;
	}

	.grid-container .contentbox-container.grey-background .content-box.only-title h2 {
		font-size: 1.45rem;
		line-height: 2.125rem;
	}

	.grid-container .contentbox-container.blue-background,
	.grid-container .contentbox-container.default,
	.grid-container .contentbox-container.grey-background {
		padding: 0.5rem 1.5rem;
	}

	.grid-container .contentbox-container.blue-background .content-box .content-box-title h2 {
		font-size: 1.45rem;
		line-height: 2.125rem;
	}

	.grid-container .contentbox-container .content-box.default ul li a {
		width: 100%;
	}
}

@media (max-width: 479px) {
	.grid-container .contentbox-container .contentbox-container-inner {
		display: flex;
		flex-direction: column;
	}
}
